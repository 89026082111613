<template>
  <div>
    <nav-bar />
    <contest-hero />
    <contest/>
    <Footer />
  </div>
</template>

<script>
import Contest from '../components/New/Contest/Contest.vue';
import ContestHero from "../components/New/Contest/ContestHero.vue";
import Footer from "../components/New/Footer.vue";
import NavBar from "../components/New/NavBar.vue";
export default {
  components: { NavBar, ContestHero, Footer, Contest },
};
</script>

<style lang="scss" scoped>
</style>