<template>
  <div class="container-fluid contest py-5">
    <div class="row flex mt-5 justify-content-center">
      <router-link to="/add_leaders_vision" class="px-4 py-2 my-3 btn-warning">Upload New Leader's Vision</router-link>
      <div
        class="col-xs-12 col-sm-12 py-3"
        v-for="(challenge, key) in leadersVisions"
        :key="key"
      >
        <ChallengeCard :challengeKey="key" :challenge="challenge" />
      </div>
    </div>
  </div>
</template>

<script>
import ChallengeCard from "../../Challenge/ChallengeCard.vue";
export default {
  components: { ChallengeCard },
  data() {
    return {
      key: value,
    };
  },
  computed: {
    leadersVisions() {
      return this.$store.getters.leadersVisions;
    },
  },
  mounted() {
    this.$store
      .dispatch("fetchLeadersVision")
      .then((responnse) => {
        if (responnse) {
          this.isLoading = false;
        }
      })
      .catch((err) => {
        this.isLoading = false;
      });
  },
};
</script>

<style  scoped>
.contest {
  padding-left: 12%;
  padding-bottom: 7em;
  padding-right: 12%;
}
</style>