<template>
    <div class="card" style="margin-bottom:20px">
    <div class="card-header">
        <h3>
            <strong class="mr-3">Issue Area :</strong>{{challenge.issue_area && challenge.issue_area[0]}}
        </h3>
    </div>
    <div class="card-description bg-challenge">
        <div class="d-flex " >
            <div class="col-12">
                <div class="col"><h4 class="pt-3 "><strong>My Vision on {{challenge.issue_area && challenge.issue_area[0]}} under AfCFTA</strong></h4> <br></div>
                  <div class="row">
                       <div class="d-flex px-3">
                          <p class="text-justify"> {{ challenge.summary.slice(0,200)}} {{ challenge.summary.split('.')[1]}}
                               <a class="text-danger" :href="`/challenge/${challenge.id}/leaders_vision`">See More..</a>
                          </p>
                      </div>
                  </div>
                 <div class="d-flex">
                    <a class="p-2" v-if="challenge.pdf" :href="`${url()}${challenge.pdf}`" target="_blank" download>pdf</a>
                    <a  class="p-2" v-if="challenge.doc" :href="`${url()}${challenge.doc}`" target="_blank" download>document</a>
                    <a  class="p-2"  v-if="challenge.image" :href="`${url()}${challenge.image}`" target="_blank" download>image</a>
                    <a  class="p-2" v-if="challenge.video" :href="`${challenge.video}`" target="_blank" >Youtube</a>
                </div>
                  <div class="d-flex justify-content-between" v-if="currentAuthor">
                     <p style="font-style:italic" >{{currentAuthor.author}}</p>
                </div>
            </div>
        </div>
        <hr>
        <div class="d-flex m-2">
            <button class="" style="float:left;" v-if="challenge.thinkers_critique.length == 0" @click="addCritique(challenge.id)">
                ADD CRITIQUE
            </button>
        </div>
        <div class="row" v-if="challenge.thinkers_critique && challenge.thinkers_critique.length > 0" >
            <div class="col-12" v-for="(critique,key) in challenge.thinkers_critique" :key="key" >
                <div class="d-flex">
                    <hr>
                    <div class="col-12" >
                         <div class="col"><h4><strong>CRITIQUE (ANTI-THESIS)</strong></h4> <br></div>
                         <div class="d-flex px-3">
                          <p class="text-justify"> {{critique.summary.slice(0,200)}} {{ challenge.summary.split('.')[1]}}
                               <a class="text-danger" :href="`/challenge/${challenge.id}/critique`">See More..</a>
                          </p>
                      </div>
                        <div class="d-flex">
                                <a class="p-2" v-if="critique.pdf" :href="`${url()}${critique.pdf}`" target="_blank" download>pdf</a>
                                <a  class="p-2" v-if="critique.doc" :href="`${url()}${critique.doc}`" target="_blank" download>document</a>
                                <a  class="p-2"  v-if="critique.image" :href="`${url()}${critique.image}`" target="_blank" download>image</a>
                            </div>
                                <a  class="p-2" v-if="critique.video" :href="`${critique.video}`" target="_blank" >Youtube</a>
                        <div class="d-flex justify-content-between">
                            <p class="pr-2"><small><strong>Created On:</strong></small> <small>{{challenge.created_at | moment}}</small></p>
                            <p><small><strong>Created By:</strong></small> <small>{{challenge.created_by[0].first_name}} {{challenge.created_by[0].last_name}} {{challenge.created_by[0].other_names}}</small></p>
                        </div>
                            
                        <button style="float:left" v-if="critique.enterpreneurs_plan == 0" @click="addPlan(critique.leaders_vision_id,critique.id)">ADD SOLUTION PLAN</button>
                    
                    </div>
                </div>
                <hr>
                  <div class="d-flex" v-if="critique.enterpreneurs_plan && critique.enterpreneurs_plan.length > 0">
                    <div class="col-12" v-for="(plan, key) in critique.enterpreneurs_plan" :key="key">
                        <div class="row">
                            <div class="col-12">
                            <div class="row">
                                <div class="col"><h4><strong>ENTERPRENEUR'S PLAN</strong></h4> <br></div>
                              <div class="col-12">
                                <div class="d-flex">
                                    <p class="text-justify"> {{ plan.summary.slice(0,200)}} {{plan.summary.split('.')[1]}}
                                        <a class="text-danger" :href="`/challenge/${challenge.id}/enterpreneurs_plan`">See More..</a>
                                    </p>  
                                </div>
                                <div class="d-flex">
                                <a class="p-2" v-if="plan.pdf" :href="`${url()}${plan.pdf}`" target="_blank" download>pdf</a>
                                <a  class="p-2" v-if="plan.doc" :href="`${url()}${plan.doc}`" target="_blank" download>document</a>
                                <a  class="p-2"  v-if="plan.image" :href="`${url()}${plan.image}`" target="_blank" download>image</a>
                                <a  class="p-2" v-if="plan.video" :href="`${plan.video}`" target="_blank" >Youtube</a>
                            </div>
                                <div class="d-flex justify-content-between">
                                <p class="pr-2"><small><strong>Created On:</strong></small> <small>{{plan.created_at | moment}}</small></p>
                                 <p><small><strong>Created By:</strong></small> <small>{{plan.created_by.first_name}} {{plan.created_by.last_name}} {{plan.created_by.other_names}}</small></p>
                            </div>
                            </div>      
                            </div> 
                            <hr>
                            
                        </div>
                        </div>
                        <button @click="contributions(challenge.id)" class="my-2" style="float:left">CONTRIBUTIONS</button>
                    </div>
                </div>
        </div>
        </div>
        <div class="row justify-content-between">
            <!-- <button class="btn btn-danger ml-5 mb-3">Submit your solution Plan</button>
            <a class="mr-5" href="#">108 comments</a> -->
        </div>
    </div>
    
    </div>   
</template>
<script>
import moment from 'moment'
import url from '../../helpers/url'
import service from '../../helpers/service'
import authors from '../../helpers/challengeAuthors'
import challengeAuthors from '../../helpers/challengeAuthors'
export default {
    name:'debateCard',
    props: {
        challenge:Object,
        challengeKey:Number
    },
     filters: {
            moment: function (date) {
                return moment(date).format('MMMM Do YYYY');
            }
            },
    data() {
        return {
            currentAuthor: {}
        }
    },
    methods:{
        url() {
            return url()
        },
        contributions(id) {
            this.$router.replace(`/challenge/${id}/contributions`)
        },
        addCritique(challengeID) {
            
            
            this.$router.replace(`/challenge/${challengeID}/add_critique`)
        },
        addPlan(challengeID,critiqueID) {
            // const user = this.$session.get('entity')
            //   if(!user) {
            //     window.location.replace(`/redirect?service=login`)
            // }
            this.$router.replace(`/challenge/${challengeID}/critique/${critiqueID}/add_plan`)
        },
        getCurrenChallenge() {
            const author = challengeAuthors.find(author => author.id == this.challenge.id)
            this.currentAuthor = author
        }
    },
    created() {
        this.getCurrenChallenge()
        console.log(this.challenge)
    }
}
</script>
<style scoped>
    button{
        background-color: #ba2428;
        color:#fff;
        border:1px solid #ba2428;
        padding:10px;
        transition: all 0.5s;
        outline: 0;
        font-size: .9em;
    }
    button:hover, button:active{
        background-color:#ad5457 ;
        color: black;
    }
    .card-header{
        background-color: #ba2428;
    }
    .card-header h3 {
        color:#fff;
    }
</style>